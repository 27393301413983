import type { ApiFetch } from './main'
import type { ApiResponse } from '~/types/misc'
import type {
  Patient,
  PatientAnamnesis,
  PatientFile,
  PatientList,
  PatientNotes,
  PatientStats,
} from '~/types/patient'

export interface PatientAPI {
  getPatientPictures: (id: string) => Promise<{ id: string; url: string }[]>
  getPatients: (
    offset?: number,
    limit?: number,
  ) => Promise<ApiResponse<PatientList>>
  getPatient: (id: string) => Promise<Patient>
  addPatient: (data: Patient) => Promise<Patient>
  updatePatient: (id: string, data: Patient) => Promise<Patient>
  deletePatient: (id: string) => Promise<any>
  getPatientStats: (id: string) => Promise<PatientStats>
  updatePatientPicture: (id: string, data: FormData) => Promise<any>
  getPatientAnamnesis: (id: string) => Promise<PatientAnamnesis>
  updatePatientAnamnesis: (
    id: string,
    data: PatientAnamnesis,
    creating?: boolean,
  ) => Promise<PatientAnamnesis>
  getPatientNotes: (
    id: string,
    limit?: number,
  ) => Promise<ApiResponse<PatientNotes>>
  updatePatientNote: (
    id: string,
    data: Partial<PatientNotes>,
    noteId?: string,
  ) => Promise<PatientNotes>
  deletePatientNote: (id: string, noteId: string) => Promise<any>
  getPatientFiles: (
    id: string,
    limit?: number,
  ) => Promise<ApiResponse<PatientFile>>
  createPatientFile: (id: string, data: FormData) => Promise<PatientFile>
  deletePatientFile: (id: string, fileId: string) => Promise<any>
}

export default (apiFetch: ApiFetch): PatientAPI => ({
  getPatientPictures(id) {
    return apiFetch(`/patients/profile-picture?id=${id}`)
  },

  getPatients(offset, limit) {
    let url = `/patients?`

    if (offset) {
      url += `offset=${offset}&`
    }

    if (limit) {
      url += `limit=${limit}&`
    }

    // Remove the trailing '&' or '?' if no parameters were added
    url = url.endsWith('&') || url.endsWith('?') ? url.slice(0, -1) : url

    return apiFetch(url)
  },

  addPatient(data) {
    return apiFetch(`/patients`, {
      method: 'POST',
      body: data,
    })
  },

  getPatient(id) {
    return apiFetch(`/patients/${id}`)
  },

  updatePatient(id, data) {
    return apiFetch(`/patients/${id}`, {
      method: 'PATCH',
      body: data,
    })
  },

  deletePatient(id) {
    return apiFetch(`/patients/${id}`, {
      method: 'DELETE',
    })
  },

  getPatientStats(id) {
    return apiFetch(`/patients/${id}/stats`)
  },

  updatePatientPicture(id, data) {
    return apiFetch(`/patients/${id}/upload-profile-picture`, {
      method: 'POST',
      body: data,
    })
  },

  getPatientAnamnesis(id) {
    return apiFetch(`/patients/${id}/anamnesis`)
  },

  updatePatientAnamnesis(id, data, creating = false) {
    return apiFetch(`/patients/${id}/anamnesis`, {
      method: creating ? 'POST' : 'PATCH',
      body: data,
    })
  },

  getPatientNotes(id, limit) {
    if (limit) {
      return apiFetch(`/patients/${id}/notes?limit=${limit}`)
    }
    return apiFetch(`/patients/${id}/notes`)
  },

  updatePatientNote(id, data, noteId) {
    if (noteId) {
      return apiFetch(`/patients/${id}/notes/${noteId}`, {
        method: 'PUT',
        body: data,
      })
    }
    return apiFetch(`/patients/${id}/notes`, {
      method: 'POST',
      body: data,
    })
  },

  deletePatientNote(id, noteId) {
    return apiFetch(`/patients/${id}/notes/${noteId}`, {
      method: 'DELETE',
    })
  },

  getPatientFiles(id, limit) {
    if (limit) {
      return apiFetch(`/patients/${id}/files?limit=${limit}`)
    }
    return apiFetch(`/patients/${id}/files`)
  },

  createPatientFile(id, data) {
    return apiFetch(`/patients/${id}/files`, {
      method: 'POST',
      body: data,
    })
  },

  deletePatientFile(id, fileId) {
    return apiFetch(`/patients/${id}/files/${fileId}`, {
      method: 'DELETE',
    })
  },
})
