import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.1.2__ioredis@5.4.1__b7ultimm5ckpxzb7v6fuvooday/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/buildhome/repo/middleware/auth.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.1.2__ioredis@5.4.1__b7ultimm5ckpxzb7v6fuvooday/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "has-permission": () => import("/opt/buildhome/repo/middleware/hasPermission.ts"),
  "is-clinic-admin": () => import("/opt/buildhome/repo/middleware/isClinicAdmin.ts")
}