import insuranceList from '~/data/insuranceList.json'
import paymentMethods from '~/data/paymentMethods.json'

export function refreshToHome() {
  // eslint-disable-next-line no-console
  console.log('Invoked refreshed to home')
  window.location.href = '/'
}

export function refreshToLogin() {
  // eslint-disable-next-line no-console
  console.log('Invoked refreshed to login')
  window.location.href = '/login'
}

export function refreshCurrentPage() {
  // eslint-disable-next-line no-console
  console.log('Invoked refreshed current page')
  window.location.reload()
}

export async function sanitizeHtml(html: string) {
  if (!html) return ''
  const DOMPurifyModule = await import('dompurify')
  const DOMPurify = DOMPurifyModule.default
  return DOMPurify.sanitize(html, { USE_PROFILES: { html: true } })
}

export function removeHtml(html: string): string {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent ?? ''
}

export function mixColors(color1: string, color2: string) {
  const c1 = hexToRgb(color1)
  const c2 = hexToRgb(color2)

  if (!c1 || !c2) return

  const mixedR = Math.floor((c1.r + c2.r) / 2)
  const mixedG = Math.floor((c1.g + c2.g) / 2)
  const mixedB = Math.floor((c1.b + c2.b) / 2)

  return rgbToHex(mixedR, mixedG, mixedB)
}

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: Number.parseInt(result[1], 16),
        g: Number.parseInt(result[2], 16),
        b: Number.parseInt(result[3], 16),
      }
    : null
}

function rgbToHex(r: number, g: number, b: number) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}

export function formatCurrency(
  value: string | number | undefined,
  currency = 'EUR',
): string {
  if (!value) return '0 €'

  if (typeof value !== 'number') {
    value = Number(value)
  }

  return new Intl.NumberFormat('pt-PT', {
    style: 'currency',
    currency,
  }).format(value)
}

export async function loadPatientForSelect(id: string) {
  const nuxtApp = useNuxtApp()
  return await nuxtApp.$api
    .getPatient(id)
    .then((response) => {
      return {
        label: `${response.first_name} ${response.last_name}`,
        value: response.id,
      }
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
      return {
        label: 'Erro ao carregar paciente',
        value: null,
      }
    })
}

export function getInsuranceLabelByValue(value: number) {
  // __original is after formkit was initialized
  return (
    insuranceList.find(
      (i) => i.value === value || (i as any)?.__original === value,
    )?.label ?? ''
  )
}

export function getPaymentMethodLabelByValue(value: number) {
  // __original is after formkit was initialized
  return (
    paymentMethods.find(
      (i) => i.value === value || (i as any)?.__original === value,
    )?.label ?? ''
  )
}
