import revive_payload_client_ee6PGUOZZj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.1.2__ioredis@5.4.1__b7ultimm5ckpxzb7v6fuvooday/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ftwb6U9u6i from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.1.2__ioredis@5.4.1__b7ultimm5ckpxzb7v6fuvooday/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_t1egzgFwzT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.1.2__ioredis@5.4.1__b7ultimm5ckpxzb7v6fuvooday/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Td4GA0HtcP from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.1.2__ioredis@5.4.1__b7ultimm5ckpxzb7v6fuvooday/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_u4HGcprijZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.1.2__ioredis@5.4.1__b7ultimm5ckpxzb7v6fuvooday/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_CoNVJwX7z4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.1.2__ioredis@5.4.1__b7ultimm5ckpxzb7v6fuvooday/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_jseRz7ZPve from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.1.2__ioredis@5.4.1__b7ultimm5ckpxzb7v6fuvooday/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hjvCkaMotf from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.11_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_CIG04c2x3C from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.1.2__ioredis@5.4.1__b7ultimm5ckpxzb7v6fuvooday/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_nrdTG3eUie from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1.79.5_terser_am3yco74inhnfson2qk5wi5zfm/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_8z55wOekRP from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1.79.5_terser_am3yco74inhnfson2qk5wi5zfm/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_oxK8lCwaVE from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1.79.5_terser_am3yco74inhnfson2qk5wi5zfm/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_xyy6tkAUk1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_Vgx6v8W3wj from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.6_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1.79.5_terse_rgtogbzj2wr2pulrlelahbm65m/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/opt/buildhome/repo/.nuxt/formkitPlugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/buildhome/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_suWrw57M9t from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.24.0_vite@5.4._jxtyfihszpeshrtq4vmngotmcu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _01_api_GIiciWaEWX from "/opt/buildhome/repo/plugins/01.api.ts";
import _02_nuxtClientInit_client_42PBK3TMYu from "/opt/buildhome/repo/plugins/02.nuxtClientInit.client.ts";
import dayjs_pGiXRjcsJO from "/opt/buildhome/repo/plugins/dayjs.ts";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
export default [
  revive_payload_client_ee6PGUOZZj,
  unhead_ftwb6U9u6i,
  router_t1egzgFwzT,
  payload_client_Td4GA0HtcP,
  navigation_repaint_client_u4HGcprijZ,
  check_outdated_build_client_CoNVJwX7z4,
  chunk_reload_client_jseRz7ZPve,
  plugin_vue3_hjvCkaMotf,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CIG04c2x3C,
  slideovers_nrdTG3eUie,
  modals_8z55wOekRP,
  colors_oxK8lCwaVE,
  plugin_client_xyy6tkAUk1,
  plugin_Vgx6v8W3wj,
  formkitPlugin_pZqjah0RUG,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_suWrw57M9t,
  _01_api_GIiciWaEWX,
  _02_nuxtClientInit_client_42PBK3TMYu,
  dayjs_pGiXRjcsJO,
  sentry_client_shVUlIjFLk
]