import type { ApiFetch } from './main'
import type {
  CreateInvoice,
  CreateInvoicePayment,
  Invoice,
  InvoiceIdAndType,
  InvoiceList,
  InvoicePayment,
  InvoiceSerie,
} from '~/types/invoices'
import type { ApiResponse } from '~/types/misc'
import { InvoiceType } from '~/types/misc'
export interface InvoicesAPI {
  getInvoices: (
    offset?: number,
    limit?: number,
    type?: InvoiceType,
    patientId?: string,
  ) => Promise<ApiResponse<InvoiceList>>
  getInvoiceSeries: () => Promise<InvoiceSerie[]>
  updateInvoiceSeries: (
    id: string | null,
    data: InvoiceSerie,
  ) => Promise<InvoiceSerie>
  deleteInvoiceSeries: (id: string) => Promise<any>
  getSingleInvoice: (
    id: string,
    type: number,
  ) => Promise<Invoice | InvoicePayment>
  createInvoice: (data: CreateInvoice) => Promise<InvoiceIdAndType>
  createInvoicePayment: (
    id: string,
    data: CreateInvoicePayment,
  ) => Promise<InvoiceIdAndType>
}

export default (apiFetch: ApiFetch): InvoicesAPI => ({
  // Invoice Series
  getInvoiceSeries() {
    return apiFetch(`/invoices/series`)
  },

  updateInvoiceSeries(id, data) {
    if (id) {
      return apiFetch(`/invoices/series/${id}`, {
        method: 'PATCH',
        body: data,
      })
    }

    return apiFetch(`/invoices/series`, {
      method: 'POST',
      body: data,
    })
  },

  deleteInvoiceSeries(id) {
    return apiFetch(`/invoices/series/${id}`, {
      method: 'DELETE',
    })
  },

  // Invoices
  getInvoices(offset, limit, type, patientId) {
    let url = '/invoices?'

    if (offset) {
      url += `offset=${offset}&`
    }

    if (limit) {
      url += `limit=${limit}&`
    }

    if (type) {
      url += `type=${type}&`
    }

    if (patientId) {
      url += `patient=${patientId}&`
    }

    // Remove the trailing '&' or '?' if no parameters were added
    url = url.endsWith('&') || url.endsWith('?') ? url.slice(0, -1) : url

    return apiFetch(url)
  },

  getSingleInvoice(id, type) {
    if (type === InvoiceType.INVOICE || type === InvoiceType.INVOICE_RECEIPT) {
      return apiFetch(`/invoices/${id}`)
    }
    return apiFetch(`/invoices/payment/${id}?type=${type}`)
  },

  createInvoice(data) {
    return apiFetch(`/invoices`, {
      method: 'POST',
      body: data,
    })
  },

  createInvoicePayment(id, data) {
    return apiFetch(`/invoices/${id}/payment`, {
      method: 'POST',
      body: data,
    })
  },
})
